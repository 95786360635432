import React from "react";
import {useEffect} from 'react';
import footer_logo from '../assets/img/rectangle-shap.svg' ;
import app_store_icon from '../assets/img/app-store-icon.png' ;
import google_play_icon from '../assets/img/google-play-icon.png' ;
function Footer(props) {
  useEffect(() => {
    const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
   
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
      if (isScriptExist && callback) callback();
    }
   
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${CAPTCHA_SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  return (
    <>
       <footer id="contact" ref={props.waitlistRef}  className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-10 col-sm-12">
                <h2>Leave Fossil Fuels Behind</h2>
                <div className='footer-input'>

                              {props.success === null || props.success === "" || props.success === undefined ? (
                                <>
                                <div className="wailist-input">
                                  <input type="email" className="form-control" name="email" id="email2"  onChange={props.handleChange}
                defaultValue="" placeholder="Enter your email address" />
                                  <button type="submit" className="btn btn-primary"  id="sub2" onClick={props.handleSubscription}>Submit</button>
                                </div> 
                                </>
                                  ) :  
                                ( <>
                                <div className="success2">  {props.success}  </div>


                              </>
                                      )
                      }
                    {props.error2 ?  <div className="error2">  {props.error2}  </div>  : ""  }

                    
                </div>
              </div>
              <div className="col-md-12 download-app">
                <p>Download our Mobile App</p>
                <ul>
                  <li>
                    <a href="https://testflight.apple.com/join/WjXVWe80" target={"_blank"} rel="noreferrer"><img style={{width :'168px'}} src={app_store_icon} alt="img" className="img-fluid app_store" /></a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=io.reactnetwork.app&hl=en&gl=US" target={"_blank"} rel="noreferrer"><img style={{width :'168px'} } src={google_play_icon} alt="img" className="img-fluid play_store" /></a>
                  </li>
                </ul>

              </div>
              <div className="col-md-12">
                <div className="social-box floating"  id='social-box-div'>
                <div className="footer_logo"><img className="mr-3" src={footer_logo}  alt="img" /> © 2023 React
                  <a className="footer-link" rel="noreferrer" href="/legal/privacy">Privacy Policy </a>
                  <a className="footer-link" rel="noreferrer" href="/legal/terms">Terms of Use </a>
                </div>
                <ul>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/react-network/">
                        <img className='img-1' src={require("../assets/img/Linkedin.png")} alt="img" />
                        <img className='img-2' src={require("../assets/img/Linkedin-2.png")} alt="img" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://twitter.com/react_energy">
                        <img className='img-1' src={require("../assets/img/Twitter.png")}  alt="img" />
                        <img className='img-2' src={require("../assets/img/Twitter-2.png")}  alt="img" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://discord.gg/cSyTFpHBMj">
                        <img className='img-1' src={require("../assets/img/Discord.png")}  alt="img" />
                        <img className='img-2' src={require("../assets/img/Discord-2.png")}  alt="img" />
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noreferrer" href="https://reactnetwork.medium.com/">
                        <img className='img-1' src={require("../assets/img/Medium.png")}  alt="img" />
                        <img className='img-2' src={require("../assets/img/Medium-2.png")}  alt="img" />
                      </a>
                    </li>
                  </ul>
                </div>

                
              </div>

            </div>
          </div>
      </footer>
    </>
 
    );
  }
  export default Footer;
  