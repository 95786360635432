import {  React  } from 'react';

import Header from "../../components/Header";
import './Shop.css';
import electricity_powerx_img from "../../assets/img/electricity-powerx-sensor-img.png";
import play_stor_mob_img from "../../assets/img/play-stor-mob-img.png";
import app_store_icon from "../../assets/img/app-store-icon.png";
import google_play_icon_2 from "../../assets/img/google-play-icon.png";
function Shop(props) {
  return (
    <>
 
 <section className="top-banner top-banner-2">
    <Header howItWorksDivREf={props.howItWorksDivREf} aboutDivRef={props.aboutDivRef} ContactDivRef={props.ContactDivRef} waitlistRef={props.waitlistRef}/>
 </section> 

  <section className='shop-section'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='shop-title'>
            <h2>PowerX Electricity</h2>
            <p>Get real-time data of your electrical usage.</p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='product-img-box'>
            <img className="img-fluid" src={electricity_powerx_img} alt="img"/>
          </div>
        
        </div>
        <div className='col-md-6'>
          <div className='product-detail-box'>
            <h3>Electricity Sensor</h3>
            <p>$259</p>
            <div className='quantity-box'>

            </div>
            <a className='btn btn-primary' rel='noreferrer' target={'_blank'} href='https://powerx.co/products/powerx-electricity'>Add to cart</a>

          </div>
        </div>
      </div>
    </div> 
  </section>

  <section className='technical-specifications'>
      <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h3>Technical specifications</h3>
            </div>

            <div className='col-md-4'>
              <h4>Description</h4>
              <h5>Electricity Sensor Dimensions</h5>
              <p>7.2 in (L) x 3.1 in (W) x 1.7 in (H) 0.8lb</p>

              <h5>PowerX Hub Dimensions</h5>
              <p>5.2 in (L) x 2.8 in (W) x 1.2 in (H)0.3lb</p>

              <h5>Clamp Cable Length</h5>
              <p>3.2 ft</p>

            </div>

            <div className='col-md-4'>
              <h4>Features</h4>
              <h5>Power Source</h5>
              <p>90-240VAC 50/60Hz</p>

              <h5>Power Consumption</h5>
              <p>3W</p>
            </div>

            <div className='col-md-4'>
              <h4>Includes</h4>
              <ul>
                <li>PowerX Electricity Sensor</li>
                <li>PowerX Hub</li>
                <li>PowerX sensor mount (optional use)</li>
                <li>USB wall adapter and power cable</li>
                <li>Set of 2 200A clamps</li>
                <li>Set of 4 50A clamps</li>
                <li>2 mounting screws (optional use)</li>
              </ul>

            </div>

          </div>
      </div>      
  </section>


{/* app-store start */}
  <section className='app-store'>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <div className='app-store-img-box text-center'>
            <img className="img-fluid" src={play_stor_mob_img} alt="img"/>
          </div>
        
        </div>
        <div className='col-md-6'>
          <div className='app-store-discription-box'>
              <h6>Use the React app to monitor energy usage, measure conservation, or check in on your earnings! We’ll be rolling out exciting new features to help you join the decentralized energy future.</h6>
              <p>Download the App and explore.</p>
              <div className='app-store-box'>
                <a href="https://testflight.apple.com/join/WjXVWe80" target={"_blank"} rel="noreferrer"><img className="img-fluid" src={app_store_icon} alt="img"/></a>
                <a href='https://play.google.com/store/apps/details?id=io.reactnetwork.app&hl=en&gl=US' target={"_blank"} rel="noreferrer"><img className="img-fluid" src={google_play_icon_2} alt="img"/></a>  
              </div>


          </div>
        </div>
      </div>
    </div>    
  </section>
  {/* app-store close */}


    </>
 
    );
  }
  export default Shop;
  