import {  React  } from 'react';

import Header from "../../components/Header";
import './Faq.css';
// import Animation from "../../components/Animation";
function Faq(props) {
  // window.location.reload(false); aboutDivRef={aboutDivRef} 
  return (
    <>
 
 <section className="top-banner top-banner-2">
 {/* <Animation/> */}
            <canvas  data-transition-in id="faq-gradient-canvas"></canvas>
            <Header howItWorksDivREf={props.howItWorksDivREf} aboutDivRef={props.aboutDivRef} ContactDivRef={props.ContactDivRef} waitlistRef={props.waitlistRef}/>
    
      <section className='faq-more-react'>
     
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <h2 className="title"><span>FAQ’s</span><br/>More on React</h2>
            </div>
          </div>

          <div className="accordion border-top">What is React?</div>
          <div className="panel border-top">
            <p>React is a decentralized, open protocol for connecting distributed energy storage (like batteries) to power markets.
                 React rewards individuals who deploy batteries in their homes or businesses. The protocol pools batteries by regions and acts as one large resource that can provide support to the local energy grid. Everyone that contributes a battery to the pool gets rewarded for the services their batteries are providing. With React, you can earn by actively supporting the energy transition.
               </p>
          </div>

          <div className="accordion border-top">Why is React important?</div>
          <div className="panel border-top">
            <p>The energy transition is one of our greatest modern infrastructure undertakings. As a society, we have collectively determined to create a more healthy and sustainable energy system for a better future of our world.<br/> <br/>
             However, renewable energy is intermittent, which is a problem because the supply and demand of electricity must always be balanced. The sun doesn’t always shine and the wind doesn’t always blow. Nuclear doesn’t solve this problem either, because it can take days to ramp nuclear power up or down. Batteries are the key for solving the problem of fluctuations in energy supply and demand, ensuring the grid remains balanced. Batteries can dispatch their stored energy on demand when the grid needs it. 
               </p>
          </div>

          <div className="accordion border-top">How big of a problem is this?</div>
          <div className="panel border-top">
            <p>Energy flexibility is one of the most significant problems to solve for as we build this new energy system. Just see California’s issues with blackouts and Texas’ problems with grid stability. This is a problem that will continue to grow in all power systems around the world.
           <br/><br/>
            The IEA estimates that we need to increase the flexibility of our energy system by 10x what it is today by 2030 to ensure the energy transition stays on track. React is focused on empowering individuals to help solve this problem.
            </p>
          </div>

          <div className="accordion border-top">How does it work?</div>
          <div className="panel border-top">
            <p>If you have a battery system for backup power in your home or business, connecting to React is simple. You just need to download the React app and follow the instructions to connect your battery. Once connected to the protocol, React handles the rest. It pools your battery with other batteries in the region, creating a venue for market participants in the power industry to contract these assets to provide grid stability services.</p>
          </div>

          <div className="accordion border-top">What does it mean that React is decentralized?</div>
          <div className="panel border-top">
            <p>React is providing a critically important role to the energy grid, providing a public good in the form of grid stability to local communities. React is open source and is owned by everyone participating in its ecosystem, instead of being owned by a single company. 
            Cryptonetworks offer us new methods of coordinating collective action, giving us the tools to incentivize actions that improve our world. This prioritizes transparency and incentive alignment between owners of batteries and the protocol. It also means there are no middlemen between you and the value you are creating. Value accrues to you, not some company in the middle.
           </p>
          </div>

          <div className="accordion border-top">What are React tokens?</div>
          <div className="panel border-top">
            <p>On top of earning cash rewards from the power market, participants are also rewarded with KWH tokens for participating. KWH tokens have governance rights over the protocol, and are a critical utility token to keeping the protocol operating. </p>
          </div>

          <div className="accordion border-top">Does participating harm my battery?</div>
          <div className="panel border-top">
            <p>Modern lithium-ion batteries are rated for 10+ year useful lives and 5000+ cycles. While participating in a network like React will require you to cycle your battery when called, the battery is well equipped to handle these cycles. Participating should not result in any meaningful degradation over the regular life of the battery.</p>
          </div>

          <div className="accordion border-top">How much can I earn?</div>
          <div className="panel border-top">
            <p>This is very regionally-specific. Some regions benefit from high returns, while others have no existing markets to participate in. Visit our open-source market calculator here to see what opportunities exist in your region.</p>
          </div>

          {/* <div className="accordion border-top">How does it work?</div>
          <div className="panel border-top">
            <p>React is a decentralized, open protocol for connecting distributed energy storage (like batteries) to power markets. React rewards individuals 
              who deploy batteries in their homes or businesses. The protocol pools batteries by regions and acts as one large resource that can provide support to the local energy grid. Everyone that contributes a battery to the pool gets rewarded for the services their batteries are providing.
                With React, you can earn by actively supporting the energy transition.</p>
          </div> */}

          <div className="accordion border-top border-bottom">Are there any tax credits for energy storage?</div>
          <div className="panel border-top">
            <p>Yes! The inflation reduction act created a new 30% investment tax credit for battery hardware and installation costs. You can visit our summary of the inflation reduction act here to learn more.</p>
          </div>
        </div>
      </section>
      </section> 
    </>
 
    );
  }
  export default Faq;
  