import {React, useEffect} from 'react';

import Header from "../../components/Header";
import './Privacy-policy.css';
// import Animation from "../../components/Animation";
function Privacy(props) {
  // window.location.reload(false); aboutDivRef={aboutDivRef}

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://app.termly.io/embed-policy.min.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

  return (
    <>

 <section className="top-banner top-banner-2">
 {/* <Animation/> */}
            <canvas  data-transition-in id="faq-gradient-canvas"></canvas>
            <Header howItWorksDivREf={props.howItWorksDivREf} aboutDivRef={props.aboutDivRef} ContactDivRef={props.ContactDivRef} waitlistRef={props.waitlistRef}/>

      <section className='faq-more-react privacy-policy'>
        <div className='container'>
            <div name="termly-embed" data-id="e4282723-9011-4f97-8261-182b9a5833e0" data-type="iframe"></div>
        </div>
      </section>
      </section>
    </>

    );
  }
  export default Privacy;
