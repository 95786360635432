import React, { useState } from "react";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header({ blogDivRef, waitlistRef }) {
  const [showMenu, setShowMenu] = useState(false);
  const scrollToBlog = (event) => {
    event.preventDefault();
    blogDivRef.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  // const scrollToWaitList = (event) => {
  //   event.preventDefault();
  //   waitlistRef.current.scrollIntoView({
  //     behavior: "smooth",
  //   });
  // };

  const scrollToBlog2 = (event, divId) => {
    setTimeout(() => {
      let section = document.querySelector(divId);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 1500);
  };

  let location = useLocation().pathname;
  const locationArray = location.split("/");

  const headerStyle =  locationArray.indexOf("shop") !== -1 ? {"marginBottom" : 0, "paddingBottom" : "20px"} : {};
  return (
    <>
      <header className="header" style={headerStyle}>
        <nav className="navbar navbar-expand-lg ">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setShowMenu(!showMenu)}
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="true"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                {" "}
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
          </button>

          <div
            className={
              showMenu
                ? "collapse navbar-collapse show"
                : `collapse navbar-collapse  `
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto">
              {locationArray.indexOf("faq") !== -1 || locationArray.indexOf("shop") !== -1 || locationArray.indexOf("privacy") !== -1 || locationArray.indexOf("terms") !== -1 ? (
                
              
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/shop">
                        SHOP
                    </Link>
                  </li>
                  <li className="nav-item active">
                    <Link
                      onClick={(event) => scrollToBlog2(event, "#blog-div")}
                      className="nav-link innerPage"
                      to="/"
                    >
                      Blog{" "}
                    </Link>
                  </li>
                </>
              ) : (
                
                <>
                  <li className="nav-item">
                    <Link className="nav-link" to="/shop">
                        SHOP
                    </Link>
                  </li>  
                  <li className="nav-item active">
                    <Link
                      onClick={(event) => scrollToBlog(event)}
                      className="nav-link"
                      to="/"
                    >
                      Blog{" "}
                    </Link>
                  </li>
                </>
              )}


              <li className="nav-item">
                <a className="nav-link" href="/faq">
                  faqs
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://docs.reactnetwork.io/introduction/what-is-react"
                >
                  Docs
                </a>
              </li>

              <li className="nav-item footer-link mob-footer-link">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="/privacy"
                >
                  Privacy Policy
                </a>
              </li>

              <li className="nav-item footer-link mob-footer-link">
                <a
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                  href="/terms"
                >
                  Terms of Use
                </a>
              </li>

              <li className="nav-item">
                <a
                  href="https://play.google.com/store/apps/details?id=io.reactnetwork.app&hl=en&gl=US" target={"_blank"} rel="noreferrer"
                  className="nav-link download-btn btn btn-primary"
                >
                  DOWNLOAD
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
}
export default Header;
