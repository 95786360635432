import "./App.css";
import Marquee from "react-fast-marquee";
import * as Constants from "./Constants";
import axios from "axios";
import { useState, useRef } from "react";
import Animation from "./components/Animation";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Faq from "./components/Faq/Faq";
import Shop from "./components/Shop/Shop";
import Privacy from "./components/privacy-policy/Privacy-policy";
import Terms from "./components/Terms-of-use/Terms-of-use";
import backup_plan_img from "./assets/img/backup-plan-img.png";
import react_globe from "./assets/img/react_globe.gif";
import banner_mob_img from "./assets/img/banner-mob-img.gif";
import arrow_icon from "./assets/img/arrow-icon.svg";
import featured_arrow_icon from "./assets/img/featured-arrow-icon.png";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [email, setEmail] = useState("");
  const [error1, setErrorOne] = useState("");
  const [error2, setErrorTwo] = useState("");
  const [success, setSuccess] = useState("");
  const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const blogRef = useRef(null);
  const waitlist = useRef(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleChange = (event) => {
    setErrorOne("");
    setErrorTwo("");
    setEmail(event.target.value);
  };
  const handleSubscription = (event) => {
    if (email !== "" && !isValidEmail(email)) {
      if (event.target.id === "sub1") {
        setErrorOne("Email is invalid");
      } else {
        setErrorTwo("Email is invalid");
      }
      return false;
    } else {
      setErrorOne("");
      setErrorTwo("");
    }

    let subAPIURL = Constants.subscriptionApi;
    if (email !== "") {
      let postData = {
        email: email,
      };
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(CAPTCHA_SITE_KEY, { action: 'submit' }).then(token => {
          postData.token = token;
          postData.site_key = CAPTCHA_SITE_KEY;

          const options = {
            headers: {
              Accept: "application/json, text/plain, /",
              "Content-Type": "multipart/form-data",
            },
          };  
          axios
            .post(subAPIURL, postData, options)
            .then((response) => {
              setErrorOne("");
              setErrorTwo("");
              if (response.status === 200) {
                setSuccess(response.data.message);
              } else {
                setSuccess("");
              }
            })
            .catch((error) => {
              console.log("error ", error.response.data.message);
              if (
                error.response.data.message !== "" &&
                event.target.id === "sub1"
              ) {
                setErrorOne(email + " " + error.response.data.message);
                // setEmail("");
              } else if (
                error.response.data.message !== "" &&
                event.target.id === "sub2"
              ) {
                setErrorTwo(email + " " + error.response.data.message);
              }
    
              setSuccess("");
            });
        });
      });
      
    } else {
      if (event.target.id === "sub1") {
        setErrorOne("Email Is Required. Please Fill Out This Field.");
      } else {
        setErrorTwo("Email Is Required. Please Fill Out This Field.");
      }
      setSuccess("");
    }
  };
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <section className="top-banner">
                  <Animation />

                  <canvas data-transition-in id="gradient-canvas"></canvas>
                  <Header blogDivRef={blogRef}  waitlistRef = {waitlist}/>

                  <div className="top-banner-bg">
                    <div className="container">
                      <div className="row direction">
                        <div className="col-md-6">
                          <div className="top-banner-left">
                            <h1 className="text-animation">
                              Energy powered
                              <br /> by<span>People</span>
                            </h1>
                            <p className="text-animation2">
                              Rewarding you for helping build a decentralized,
                              sustainable, and stable energy grid system
                            </p>
                            <a
                              className="btn btn-default get-started-btn"
                              href="https://play.google.com/store/apps/details?id=io.reactnetwork.app&hl=en&gl=US" target={"_blank"} rel="noreferrer"
                            >
                              Get Started
                            </a>
 
                          </div>
                        </div>
                        <div className="col-md-6 ">
                          <div className="top-banner-right ">
                            <div className="element-1">
                              {}
                            </div>

                            <div className="globe-box">
                              {/* <img className="img-fluid globe-img" src={require("./assets/img/globe.png")} alt="img" /> */}

                               <img className="img-fluid" src={banner_mob_img} alt="img"/>
                            </div>

                            <div className="element-2">
                              {}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="change-everything">
                      <iframe
                        title="wave-animation-container"
                        className="animation-2"
                        src="https://lottie.host/?file=56c8dbf6-dea9-4881-a508-a8a4c2b34f84/JOgfpo6WTu.json"
                      ></iframe>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="change-everything-left">
                              <img
                                className="img-fluid"
                                src={react_globe}
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="change-everything-right">
                              <h3>
                                a spark
                                <br /> can change <span>everything</span>
                              </h3>
                              <p>The React Network is composed of thousands of 
                              connected batteries in homes, businesses and 
                              multi-family living spaces, deployed by people 
                              just like you. With all these batteries sharing 
                              their extra energy, React is able to help support 
                              the power grid in times of need. gives grid operators 
                              a clean and stable alternative to running inefficient, 
                              dirty power plants{" "}</p>
                              <br />
                              <a
                                className="learn-more"
                                rel="noreferrer"
                                href="https://docs.reactnetwork.io/"
                                target="_blank"
                              >
                                Learn More{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Marquee
                        gradient={false}
                        speed={34}
                        className="marquee-text"
                      >
                        This website emits 0.2g of CO2 This website emits 0.2g of CO2 This website emits 0.2g of CO2
                      </Marquee>
                    </div>

                    {/* <div className="changing-world">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-animation">Changing the World With</h2>
              </div>
              <div className="col-md-8 m-auto">
                <ul className="large row">
                    <li className="col-md-6 text-center">
                      <a target="_blank" rel="noreferrer"  href="https://www.lererhippeau.com/">
                        <img src={require("./assets/img/logo-lerer-hippeau.webp")} alt="img" className="with-out-hover img-fluid" /><br/> 
                        <img src={require("./assets/img/logo-lerer-hippeau-hover.png")} alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                    <li className="col-md-6 text-center">
                      <a  target="_blank" rel="noreferrer" href="https://lattice.fund/">
                        <img src={require("./assets/img/logo-lattice.webp")}  alt="img" className="with-out-hover img-fluid" /> <br/>
                        <img src= {require("./assets/img/logo-lattice-hover.png")} alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                </ul>
              </div>
              <div className="col-md-12">
              <ul className="small">
                    <li className="list">
                      <a target="_blank" rel="noreferrer" href="https://dcg.co/">
                        <img src={require("./assets/img/logo-dcg.webp")}  alt="img" className="with-out-hover img-fluid" /> 
                        <img src={require("./assets/img/logo-dcg-hover.png")} alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>

                    <li className="">
                      <a target="_blank" rel="noreferrer"  href="https://coinshares.com/">
                        <img src={require("./assets/img/logo-coinshares1.webp")}  alt="img" className="with-out-hover img-fluid" /> 
                        <img src={require("./assets/img/logo-coinshares-hover1.png")} alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                    <li className="">
                      <a target="_blank" rel="noreferrer" href="https://vaynerfund.com/">
                        <img src={require("./assets/img/logo-vaynerfund1.webp")} alt="img" className="with-out-hover img-fluid" /> 
                        <img src={require("./assets/img/logo-vaynerfund1.png")}  alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                    <li className="">
                      <a target="_blank" rel="noreferrer" href="https://allegory.earth/">
                        <img src={require("./assets/img/logo-allegory1.webp")} alt="img" className="with-out-hover img-fluid" /> 
                        <img src={require("./assets/img/logo-allegory-hover1.png")}  alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                    <li className="">
                      <a target="_blank" rel="noreferrer" href="https://gaingels.com/">
                        <img src={require("./assets/img/logo-gaingels1.webp")} alt="img" className="with-out-hover img-fluid" /> 
                        <img src={require("./assets/img/logo-gaingels-hover1.png")} alt="img" className="hover-img img-fluid" />
                      </a>
                    </li>
                    <li className="">
                      <a target="_blank" rel="noreferrer" href="https://www.climatecapital.co/">
                        <img src={require("./assets/img/climate-capital1.png")} alt="img" className="with-out-hover img-fluid climate-capital-logo" /> 
                        <img  src={require("./assets/img/climate-capital-hover.png")} alt="img" className="hover-img img-fluid climate-capital-logo" />
                      </a>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
                  </div>
                </section>
                <section
                  className="massive-impact"
                  style={{ display: "grid !important" }}
                >
                  {/* <Marquee gradient={false} speed={34} className="marquee-text">
      This website emits 0.2g of CO2 | This website emits 0.2g of CO2 | This website emits 0.2g of CO2
      </Marquee> */}

                  {/* <div className="massive-impact-bg-shap">
        <div className="container">
            <div className="massive-impact-content  text-animation">
              <div className="">
                <div className="row">
                  <div className="col-md-6">
                    <div className="massive-impact-left">
                      <img className="img-fluid" src={massive_impact}  alt="img" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="massive-impact-right">
                      <h2 className="title text-animation">Small Actions.<br/><span>Massive Impact</span> </h2>
                      <p className="text-animation2">The React Network is comprised of thousands of connected smart devices. With many systems working together, small, infrequent adjustments can become large amounts of energy to help support the electricity grid in times of need. The React Network gives grid operators a clean alternative to running inefficient, dirty power plants.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>  */}
                  {/* <Marquee  gradient={false}	speed={34} className="marquee-text mb-0" >
      The Network That Supports Your Grid | The Network That Supports Your Grid |
      </Marquee> */}
                </section>

                <section className="future-focused-parent">
                  <Animation />
                  {/* <canvas id="feature-focused-gradient-canvas" ></canvas> */}
                  <section className="future-focused" id="how-it-work">
                    <div className="container">
                      <div
                        ref={blogRef}
                        id="blog-div"
                        className="energy-together-content"
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <div className="left-contnt">
                              <h2 className="title text-animation">
                              <span>Easy as 1-2-3</span> <br />
                                  Share Energy,<br />Earn Together.
                              </h2>
                              
                              <p>
                                The grid rewards the React community for helping
                                build a more stable and sustainable energy
                                system. It’s a win-win!
                              </p>
                              <a
                                className="btn btn-default get-started-btn"
                                href="https://play.google.com/store/apps/details?id=io.reactnetwork.app&hl=en&gl=US" target={"_blank"} rel="noreferrer"
                              >
                                Get Started
                              </a>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="right-content">
                              <div className="text-animation">
                                <div className="card m-auto">
                                  <h3 className="text-animation">
                                    1. Connect your energy monitor
                                  </h3>
                                  <p className="text-animation2">
                                  Connect a smart energy sensor in your home to React, and it will share its excess energy with a regional pool. Don’t have one? You can purchase one{" "}
                                    <a
                                      href="https://docs.reactnetwork.io/introduction/what-is-react"
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      {" "}
                                      here!{" "}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="text-animation">
                                <div className="card m-auto">
                                  {/* <img className="w-85" src={energy_supply} alt="icon" /> */}
                                  <h3 className="text-animation">
                                    2. Sync all the energy devices in your home
                                  </h3>
                                  <p className="text-animation2">
                                    You can sync your home battery, thermostat, and EV charger  through the React Mobile App. Monitor your energy consumption & get smart insights.
                                  </p>
                                </div>
                              </div>
                              <div className="text-animation">
                                <div className="card m-auto">
                                  {/* <img className="w-85" src={stabilize_grid}  alt="icon" /> */}
                                  <h3 className="text-animation">
                                    3. Earn and help limit climate change!
                                  </h3>
                                  <p className="text-animation2">
                                    The grid dispatches the React Network as a last resort for additional power instead of dirty power plants, ensuring our grid remains clean and stable.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="learn-more">
                        <a href="faq">
                          Check out our <span>FAQs</span> to learn more
                        </a>
                      </p>
                    </div>
                  </section>

                  <section className="backup-plan">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="backup-plan-left">
                            <h3>
                            A collective<br /><span>backup plan.</span>
                            </h3>
                            <p>The React Community is creating the largest data and connectivity 
                               layer to build the grid of the future. By building a real-time data 
                               network and connecting distributed energy resources, we can optimize 
                               how we manage the grid, turn buildings into energy resources, and 
                               create a better, empowered, consumer-first energy system.</p>
                            <br />
                            <a
                              href="https://docs.reactnetwork.io/introduction/what-is-react"
                              rel="noreferrer"
                              target="_blank"
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="backup-plan-right">
                            <img
                              className="img-fluid"
                              src={backup_plan_img}
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* <section className="massive-impact fixing-planet">
      <div className="massive-impact-bg-shap">
        <div className="container">
            <div className="massive-impact-content">
              <div className="">
                <div className="row">
                  
                  <div className="col-md-6">
                    <div className="massive-impact-right  text-animation">
                    <h2 className="title"><span>Strong communities</span><br/>are electric.</h2>                  
                      <p>React is a community-owned network that connects your battery to power markets. Earning is as easy as connecting your home battery system to the network via the React app. Your devices immediately start earning for participating. Passive income, for an active fight against climate change and unstable energy grids.</p>
                  
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="massive-impact-left  text-animation">
                      <img className="img-fluid" src={fixing_planet_img} alt="img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div> 
    </section> */}
                </section>
                {/* <section className="massive-impact-marquee">
    <Marquee  gradient={false}	speed={34} className="marquee-text mb-0" >
      Good for the world, and your walleT | Good for the world, and your walleT
    </Marquee>
  </section> */}
                <section id="network" className="meet-network-new">
                  <div
                    id="second"
                    className="section-meet section--vertical meet-network-slider"
                  >
                    <div className="block-list  text-animation">
                      <div className="item scroll_Item block-list__item">
                        <div className="meet-network-slider-content block-list__item-inner">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="meet-title">
                                <h2>
                                  About <span>React</span>
                                </h2>
                                <img
                                  src={arrow_icon}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="img-box meet-left">
                                <img
                                  src={require("./assets/img/about1.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="meet-right">
                                <h3>
                                  Renewable energy is <br />
                                  growing fast
                                </h3>
                                <p>That’s a good thing for our environment. However, 
                                   renewable energy can also be unstable, because the wind isn’t 
                                   always blowing and the sun isn’t always shining.<br /> <br />

                                   Batteries help us fix this problem by storing electrons until 
                                   they are needed. The more batteries distributed all across the grid, 
                                   the more useful they are! React uses economic incentives to encourage 
                                   battery deployments, and pools them together for the grid to use as a 
                                   resource. Similar to a beehive, an energy cooperative pools resources 
                                   to share among its members. Bees store extra honey for the winter, 
                                   the same way your batteries will deploy their extra energy to the grid 
                                   for later communal use. <br /> <br />

                                   React’s infrequent calls to discharge add up to a massive source of 
                                   value for energy grids– value that is returned to your wallet.
                                  
                                  
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="item scroll_Item block-list__item"
                        id="aboutus"
                      >
                        <div className="meet-network-slider-content block-list__item-inner">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="meet-title">
                                <h2>About React</h2>
                                <img
                                  src={arrow_icon}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="img-box meet-left">
                                <img
                                  src={require("./assets/img/about2.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="meet-right">
                                <h3>A decentralized platform </h3>
                                <p>
                                  The React Network belongs to its builders. 
                                  Our energy infrastructure is too important to 
                                  be controlled by just a few large corporations. 
                                  As a community-owned energy protocol, your 
                                  energy earns for you - not some company.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item scroll_Item block-list__item">
                        <div className="meet-network-slider-content block-list__item-inner">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="meet-title">
                                <h2>About React</h2>
                                <img
                                  src={arrow_icon}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="img-box meet-left">
                                <img
                                  src={require("./assets/img/about3.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="meet-right">
                                <h3>Empowering you to succeed</h3>
                                <p>
                                  Once connected, your battery begins to earn
                                  automatically. React handles the rest.
                                  <br />
                                  <br />
                                  Your battery will earn both cash and crypto
                                  for participating. The React Network provides
                                  additional rewards for cleaning up our grids
                                  with via cash and token rewards. This gives
                                  you governance over the React Network, as well
                                  as other benefits.
                                  <br />
                                  <br />
                                  As our community grows, the React Network
                                  grows more valuable in its ability to effect
                                  meaningful change. Because there’s power in
                                  numbers.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <Marquee gradient={false} speed={34} className="marquee-text">
                This website emits 0.2g of CO2 This website emits 0.2g of CO2 This website emits 0.2g of CO2
                </Marquee>

                <section className="featured">
                  <canvas id="feature-focused-gradient-canvas"></canvas>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <h2>As featured in</h2>
                      </div>

                      <div className="col-md-12">
                        <ul>
                          <li>
                            <a
                              href="https://www.axios.com/pro/climate-deals/newsletters/2022/11/17/climate-tenets-25m-credit#"
                              rel="noreferrer"
                              target="_blank"
                            >
                              Axios{" "}
                              <img
                                src={featured_arrow_icon}
                                alt="img"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://coinmarketcap.com/headlines/news/anode-labs-raises-4-2-million-to-build-web3-energy-grid/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              The Block{" "}
                              <img
                                src={featured_arrow_icon}
                                alt="img"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://finance.yahoo.com/news/anode-labs-announces-funding-build-140200266.html"
                              rel="noreferrer"
                              target="_blank"
                            >
                              Yahoo Finance{" "}
                              <img
                                src={featured_arrow_icon}
                                alt="img"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.theblock.co/post/187115/anode-labs-raises-4-2-million-to-build-web3-energy-grid"
                              rel="noreferrer"
                              target="_blank"
                            >
                              CoinMarketCap{" "}
                              <img
                                src={featured_arrow_icon}
                                alt="img"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="changing-world">
                    <h2 className="text-animation ">Changing the World With</h2>
                    <Marquee
                      gradient={false}
                      speed={34}
                      className="marquee-text"
                    >
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.lererhippeau.com/"
                      >
                        <img
                          src={require("./assets/img/logo-lerer-hippeau.webp")}
                          alt="img"
                          className="logo-lerer"
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://lattice.fund/"
                      >
                        <img
                          src={require("./assets/img/logo-lattice.webp")}
                          alt="img"
                          className="logo-lattice"
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://dcg.co/"
                      >
                        <img
                          src={require("./assets/img/logo-dcg.webp")}
                          alt="img"
                          className="logo-dcg"
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://coinshares.com/"
                      >
                        <img
                          src={require("./assets/img/logo-coinshares1.webp")}
                          alt="img"
                          className="logo-coinshares1"
                        />
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://vaynerfund.com/"
                      >
                        <img
                          src={require("./assets/img/logo-vaynerfund1.webp")}
                          alt="img"
                          className="logo-vaynerfund1"
                        />{" "}
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://allegory.earth/"
                      >
                        <img
                          src={require("./assets/img/logo-allegory1.webp")}
                          alt="img"
                          className="logo-allegory1"
                        />{" "}
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://gaingels.com/"
                      >
                        <img
                          src={require("./assets/img/logo-gaingels1.webp")}
                          alt="img"
                          className="logo-gaingels1"
                        />{" "}
                      </a>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.climatecapital.co/"
                      >
                        <img
                          src={require("./assets/img/climate-capital1.png")}
                          alt="img"
                          className="climate-capital1"
                        />{" "}
                      </a>
                    </Marquee>
                  </div>
                </section>
              </>
            }
          ></Route>
          <Route
            exact
            path="/shop"
            element={<Shop blogDivRef={blogRef} waitlistRef = {waitlist}/>}
          ></Route>
          <Route
            exact
            path="/faq"
            element={<Faq blogDivRef={blogRef} waitlistRef = {waitlist}/>}
          ></Route>
          <Route
            exact
            path="/legal/privacy"
            element={<Privacy blogDivRef={blogRef} waitlistRef = {waitlist}/>}
          ></Route>
          <Route
            exact
            path="/legal/terms"
            element={<Terms blogDivRef={blogRef} waitlistRef = {waitlist}/>}
          ></Route>
        </Routes>
      </Router>

      <Footer
        email={email}
        error1={error1}
        error2={error2}
        success={success}
        handleChange={handleChange}
        handleSubscription={handleSubscription}
        waitlistRef = {waitlist}
      />
    </>
  );
}

export default App;